import { Box, Flex, Switch, Text } from '@lego/klik-ui';
import { useFormikContext } from 'formik';
import { useCallback, useContext, useState } from 'react';
import { SingleValue } from 'react-select';
import * as Yup from 'yup';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { IntlContext } from '../../i18n/intl/intl-context';
import { IMaterialRequest } from '../../models/material-request';
import { CanceledError } from '../../network/errors/canceled-error';
import { searchVendors } from '../../network/search-vendors';
import { groupBy } from '../../utils/group-by';
import { nameof } from '../../utils/nameof';
import { IAsyncSelectOption, TLoadOptions } from '../search-dropdown/search-dropdown';
import { InputFormControl } from './form-controls/input-form-control';
import { InputWithAddonFormControl } from './form-controls/input-with-addon-form-control';
import { SearchDropdownFormControl } from './form-controls/search-dropdown-form-control';

export const vendorFormValidationSchema = Yup.object<
  Partial<Record<keyof IMaterialRequest, Yup.AnySchema>>
>({
  Vendor: Yup.string().when(nameof<IMaterialRequest>('VendorInfoRequired'), {
    is: true,
    then: (schema) => schema.required('validation.required'),
  }),
  VendorMaterialNumber: Yup.string().when(nameof<IMaterialRequest>('VendorInfoRequired'), {
    is: true,
    then: (schema) => schema.required('validation.required'),
  }),
  VendorDeliveryDays: Yup.number().when(nameof<IMaterialRequest>('VendorInfoRequired'), {
    is: true,
    then: (schema) => schema.required('validation.required'),
  }),
  VendorPrice: Yup.number().when(nameof<IMaterialRequest>('VendorInfoRequired'), {
    is: true,
    then: (schema) => schema.required('validation.required'),
  }),
  // TODO Uncomment code below when standard price is provided
  /*StandardPrice: Yup.number().when(nameof<IMaterialRequest>('VendorInfoRequired'), {
    is: true,
    then: (schema) => schema.required('validation.required'),
  }),*/
});

export const VendorForm: React.FC = () => {
  // TODO Uncomment code below when standard price is provided
  //const [isStdPriceSameAsVendorPrice, setIsStdPriceSameAsVendorPrice] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [selectedVendor, setSelectedVendor] = useState<string | undefined>(undefined);

  const { values, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<IMaterialRequest>();

  const intl = useContext(IntlContext);

  const formSetFieldValue = async (field: string, value: boolean | string) => {
    // setFieldValue uses setState internally which is async
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await setFieldValue(field, value);
    setFieldTouched(field);
    handleChange(field);
  };

  // TODO Uncomment code below when standard price is provided
  /*const onStdPriceSameAsVendorPriceChange = async () => {
    const standardPrice = !isStdPriceSameAsVendorPrice ? values.VendorPrice : '';
    await formSetFieldValue(nameof<IMaterialRequest>('StandardPrice'), standardPrice);
    setIsStdPriceSameAsVendorPrice(!isStdPriceSameAsVendorPrice);
  };*/

  const onVendorInfoRequiredChange = async () => {
    await formSetFieldValue(
      nameof<IMaterialRequest>('VendorInfoRequired'),
      !values.VendorInfoRequired,
    );
  };

  const onVendorPriceChange = async (price: string) => {
    // TODO Uncomment code below when standard price is provided
    /*if (isStdPriceSameAsVendorPrice) {
      await formSetFieldValue(nameof<IMaterialRequest>('StandardPrice'), price);
    }*/
  };

  const onSearch = useCallback(async (searchText: string): Promise<IAsyncSelectOption[]> => {
    const results = await searchVendors(searchText, true);
    const resultsGrouped = groupBy(results, (i) => i.SupplierNumber);

    return Array.from(resultsGrouped.entries())
      .sort(([_, av], [otherValue, bv]) => bv.length - av.length)
      .slice(0, 100)
      .map(([k, v]) => ({ value: k, label: v[0].Name }));
  }, []);

  let timeoutId: NodeJS.Timeout;

  const onSearchDelayed: TLoadOptions = (inputValue, callback) => {
    clearTimeout(timeoutId);
    setErrorMessage(undefined);

    timeoutId = setTimeout(async () => {
      try {
        callback(await onSearch(inputValue));
      } catch (error) {
        if (!(error instanceof CanceledError)) {
          setErrorMessage(
            intl.formatMessage({
              id: 'error.errorOccurred',
            }),
          );

          callback([]);
        }
      }
    }, 500);
  };

  const onVendorChange = (selectedOption: SingleValue<IAsyncSelectOption>) => {
    setSelectedVendor(selectedOption?.label);
  };

  return (
    <>
      {/* TODO Enable when Info Record part is working */}
      <Flex justifyContent="space-between" marginBottom="24px" opacity="40%" pointerEvents="none">
        <Text>
          <FormattedMessage id="vendorForm.vendorInfoRequired" />
        </Text>
        <Switch
          aria-label="Vendor information not required"
          isChecked={values.VendorInfoRequired}
          onChange={onVendorInfoRequiredChange}
        />
      </Flex>
      <Box
        opacity={values.VendorInfoRequired ? 'initial' : '40%'}
        pointerEvents={values.VendorInfoRequired ? 'auto' : 'none'}
      >
        <SearchDropdownFormControl
          defaultValue={selectedVendor}
          errorMessage={errorMessage}
          fieldName="Vendor"
          label={intl.formatMessage({ id: 'vendorForm.vendorLabel' })}
          loadOptions={onSearchDelayed}
          onValueChange={onVendorChange}
          placeholder={intl.formatMessage({ id: 'vendorForm.findVendor' })}
          tooltip={intl.formatMessage({
            id: 'vendorForm.vendorLabelTooltip',
          })}
        />
        <InputFormControl
          fieldName="VendorMaterialNumber"
          label={intl.formatMessage({
            id: 'vendorForm.vendorMaterialNumberLabel',
          })}
          tooltip={intl.formatMessage({
            id: 'vendorForm.vendorMaterialNumberLabelTooltip',
          })}
        />
        <InputWithAddonFormControl
          addonText={intl.formatMessage({ id: 'vendorForm.days' })}
          fieldName="VendorDeliveryDays"
          label={intl.formatMessage({ id: 'vendorForm.vendorDeliveryDaysLabel' })}
          tooltip={intl.formatMessage({
            id: 'vendorForm.vendorDeliveryDaysLabelTooltip',
          })}
        />
        <InputWithAddonFormControl
          addonText="DKK"
          fieldName="VendorPrice"
          label={intl.formatMessage({ id: 'vendorForm.vendorPriceLabel' })}
          onValueChange={onVendorPriceChange}
          tooltip={intl.formatMessage({
            id: 'vendorForm.vendorPriceLabelTooltip',
          })}
        />
        {/* TODO Display code below when standard price is provided */}
        {/* <Box>
          <Flex justifyContent="space-between" marginBottom="24px">
            <Text>
              <FormattedMessage id="vendorForm.stdPriceSameAsVendorPrice" />
            </Text>
            <Switch
              aria-label="Standard price same as vendor price"
              isChecked={isStdPriceSameAsVendorPrice}
              onChange={onStdPriceSameAsVendorPriceChange}
            />
          </Flex>
          {!isStdPriceSameAsVendorPrice && (
            <InputWithAddonFormControl
              addonText="DKK"
              fieldName="StandardPrice"
              label={intl.formatMessage({ id: 'vendorForm.stdprice' })}
            />
          )}
        </Box> */}
      </Box>
    </>
  );
};
